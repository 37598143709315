import { isArray } from "util";

export const formatDate = (date) => new Date(date).toLocaleDateString()
export const formatNumber = (num) => num.toLocaleString("es", { maximumFractionDigits: 0 })
export const calcDeposit = (item_cost, alquiler) => formatNumber((item_cost - alquiler.cost))
export const sumCost = (items) => items.reduce((acc, e) => acc + e.cost, 0);
export const calcDebt = (item, alquiler) => formatNumber((alquiler.cost - item.cost))
export const formatCI = (cedula) => {
  let cedulaNum = cedula.match(/\d/g, '');
  let a = (cedulaNum && cedulaNum.length) ? cedulaNum : []// [...cedula]
  a = a.reverse()
    .map((el, i, obj) => {
      if (i === 1) return `${el}-`
      else if ((i === 3 && obj.length > 4) || (i === 6 && obj.length > 7)) return `.${el}`
      else return el;
    })
    .reverse()
    .join('')
  return a;
}



const calculateWarranty = (items) => {
  let warranty = 0;
  const quantityItems = items.length;
  let falOrTop = false;
  for (let i = 0; i < quantityItems; i++) {
    let item = items[i];
    if (item.type === 'GAL' || item.type === "PRE") {
      warranty += 500;
    } else if ((item.type === "FAL" || item.type === "TOP") && !falOrTop) {
      falOrTop = true;
      warranty += 500;
    }
  }
  if (!warranty) {
    warranty = 500;
  }
  return warranty
}

export const prepareContract = (rent) => {
  const contract = {};
  const { client } = rent
  let { items } = rent

  if (!isArray(items)) items = [rent.item]

  contract.id = rent.id
  contract.items = items.map(e => e.item_code + ' - ' + e.description)
  contract.client_name = client.name
  contract.client_ci = formatCI(client.document_id)
  contract.created = formatDate(rent.created)
  contract.pickup_date = formatDate(rent.pickup_date)
  contract.return_date = formatDate(rent.return_date)
  contract.client_cel = client.phone_number
  contract.client_add = client.address
  contract.client_email = client.email_address
  contract.deposit = rent.cost
  contract.amount_to_be_paid = rent.net_cost - rent.paid_ammount - rent.client_balance
  contract.client_observations = rent.observations ?
    rent.observations.map(e => e.description) : []
  contract.warranty = calculateWarranty(items);
  contract.store = rent.store;

  return contract
}
